<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Formulir Penebangan </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updatePenebangan">
            <CRow>
              <CCol sm="6">             
                <div class="form-group">
                <label>Jenis Pengajuan</label>
                  <select class="form-control" v-model="jenis">
                    <option value="" disabled selected>Pilih Jenis</option>
                    <option value="penebangan">Penebangan</option>
                    <option value="pemangkasan">Pemangkasan</option>
                  </select>
                </div>
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="nama_pemohon"
                  label="Nama Pemohon"
                  placeholder="Input Nama Pemohon"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="alamat_pemohon"
                  label="Alamat Pemohon"
                  placeholder="Input Alamat Pemohon"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="email_pemohon"
                  label="Email Pemohon"
                  placeholder="Input Email Pemohon"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  type="text"
                  v-model="tlp_pemohon"
                  label="No Telepon Pemohon"
                  placeholder="Input No Telepon Pemohon"
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  v-model="alamat_pohon"
                  label="Alamat Pohon"
                  placeholder="Input Alamat Pohon"
                />
              </CCol>
              <CCol sm="12">
                <CTextarea
                  v-model="keterangan"
                  label="Keterangan"
                  placeholder="Input Keterangan"
                />  
              </CCol>

              <CCol sm="6">
                <div class="form-group">
                  <label>Foto Pohon</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange1($event)" id="inputGroupFile01"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                    <input type="hidden" v-model="foto1">
                  </div>
                </div>  
              </CCol>

            </CRow>
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/penebangan">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>      
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {Datepicker },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      jenis : "",      
      nama_pemohon : "",      
      alamat_pemohon : "",
      email_pemohon : "",
      tlp_pemohon : "",
      alamat_pohon : "",
      keterangan : "",
      foto_pohon : "",
      foto_pohon_text : "",
      placeholder : "Pilih File",
      selected : [], // Must be an array reference!
      show: true,
      modalError:false,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.showPenebangan();
  // this.loadData();
  },
  methods: {

    onFileChange1(event) {
      var fileData = event.target.files[0];
      this.foto_pohon_text = fileData.name;
      this.foto_pohon = fileData;
      this.placeholder = fileData.name;
    },

    // loadData: function(event){
      
    //   let username =window.btoa(this.$route.params.id_user);
    //   console.log(username);

    //   axios.get(process.env.VUE_APP_BASE_URL+"rubriks")
    //     .then((response) => {
    //           this.rubriks=response.data;
    //     })
    // },

    updatePenebangan: function(event){
      
      const formData = new FormData()

      formData.append('jenis_pengajuan', this.jenis)
      formData.append('id_penebangan', this.$route.params.id_penebangan)      
      formData.append('nama_pemohon', this.nama_pemohon)
      formData.append('alamat_pemohon', this.alamat_pemohon)
      formData.append('email_pemohon', this.email_pemohon)
      formData.append('tlp_pemohon', this.tlp_pemohon)
      formData.append('alamat_pohon', this.alamat_pohon)
      formData.append('keterangan', this.keterangan)
      formData.append('foto1', this.foto1)

      if(this.foto_pohon_text){
        formData.append('foto_pohon', this.foto_pohon, this.foto_pohon.name)
        formData.append('foto_pohon_text', this.foto_pohon.name)
      }

      axios.post(process.env.VUE_APP_BASE_URL+"penebangan/"+this.$route.params.id_penebangan, formData)
        .then((response) => {
            if(response.data.status==='success'){
              this.$router.push('/penebangan');
            }
            else{
              this.modalError = true;
            }  
        })
    },
    
    showPenebangan: function(event){
      // Simple POST request with a JSON body using axios
      const penebangan = { 
                          nama_pemohon:this.nama_pemohon,
                          alamat_pemohon:this.alamat_pemohon,
                          email_pemohon:this.email_pemohon,
                          tlp_pemohon:this.tlp_pemohon,
                          alamat_pohon:this.alamat_pohon,
                          keterangan:this.keterangan
                          };

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"penebangan/"+this.$route.params.id_penebangan)
        .then((response) => {

              const json = response.data.data;
              this.nama_pemohon=json.nama_pemohon;
              this.alamat_pemohon=json.alamat_pemohon;
              this.email_pemohon=json.email_pemohon;
              this.tlp_pemohon=json.tlp_pemohon;
              this.alamat_pohon=json.alamat_pohon;
              this.keterangan=json.keterangan;
              this.placeholder=json.foto_pohon;             
              this.foto1=json.foto_pohon;
        })
    }
  }
}
</script>